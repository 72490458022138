import DownloadIcon from "@redotech/redo-web/arbiter-icon/download-02_filled.svg";
import AttachmentIcon from "@redotech/redo-web/arbiter-icon/file-type-attachment_solid.svg";
import AudioSvg from "@redotech/redo-web/arbiter-icon/file-type-audio_solid.svg";
import DocumentIcon from "@redotech/redo-web/arbiter-icon/file-type-document_solid.svg";
import FolderIcon from "@redotech/redo-web/arbiter-icon/file-type-folder_solid.svg";
import PDFIcon from "@redotech/redo-web/arbiter-icon/file-type-pdf_solid.svg";
import VideoSvg from "@redotech/redo-web/arbiter-icon/file-type-video-02_solid.svg";
import { ImageLightbox } from "@redotech/redo-web/image-lightbox";
import { ReactElement, memo, useState } from "react";

import { Tooltip } from "@mui/material";
import { Lightbox } from "@redotech/redo-web/lightbox";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "./arbiter-components/buttons/redo-button";
import * as attachmentThumbnailCss from "./attachment-thumbnail.module.css";

interface FileThumbnailProps {
  url: string;
  description?: string;
  mimeType: string | undefined;
  icon?: ReactElement;
  typeName: string;
  showTooltip?: boolean;
  restrictWidth?: boolean;
}

const fileInfo: Map<
  RegExp,
  {
    Element: (props: FileThumbnailProps) => JSX.Element;
    icon: ReactElement;
    typeName: string;
  }
> = new Map([
  [
    /audio.*/,
    {
      Element: (props) => <AudioVideoThumbnail {...props} typeName="Audio" />,
      icon: <AudioSvg />,
      typeName: "Audio",
    },
  ],
  [
    /video.*/,
    {
      Element: (props) => <AudioVideoThumbnail {...props} typeName="Video" />,
      icon: <VideoSvg />,
      typeName: "Video",
    },
  ],
  [
    /image.*/,
    {
      Element: (props) => <ImageThumbnail {...props} />,
      icon: <AttachmentIcon />, // Unused, only included to conform to interface
      typeName: "Image",
    },
  ],
  [
    /text\/plain/,
    {
      Element: (props) => <FileThumbnail {...props} />,
      icon: <DocumentIcon />,
      typeName: "Text",
    },
  ],
  [
    /application\/pdf/,
    {
      Element: (props) => <FileThumbnail {...props} />,
      icon: <PDFIcon />,
      typeName: "PDF",
    },
  ],
  [
    /application\/zip/,
    {
      Element: (props) => <FileThumbnail {...props} />,
      icon: <FolderIcon />,
      typeName: "ZIP archive",
    },
  ],
  [
    /.*/,
    {
      Element: (props) => <FileThumbnail {...props} />,
      icon: <AttachmentIcon />,
      typeName: "File",
    },
  ],
]);
function getFileInfo(mimeType: string | undefined) {
  mimeType = mimeType || "";
  for (const [pattern, info] of fileInfo) {
    if (pattern.test(mimeType)) {
      return info;
    }
  }
  // Should never reach this line since the last regex matches all strings
  throw new Error("No file info found");
}

export const AttachmentThumbnail = memo(function AttachmentThumbnail(
  props: {
    url: string;
    mimeType: string | undefined;
  } & Partial<FileThumbnailProps>,
) {
  const { Element, icon, typeName } = getFileInfo(props.mimeType);
  return <Element icon={icon} typeName={typeName} {...props} />;
});

const ImageThumbnail = memo(function ImageThumbnail({
  url,
  description,
  mimeType,
  typeName,
}: FileThumbnailProps) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  return (
    <FileThumbnailContents
      description={description}
      icon={
        <>
          <img
            alt={description}
            className={attachmentThumbnailCss.image}
            onClick={() => setIsLightboxOpen(true)}
            src={url}
          />
          <ImageLightbox
            imageSrc={url}
            onClose={() => setIsLightboxOpen(false)}
            open={isLightboxOpen}
          />
        </>
      }
      mimeType={mimeType}
      onClick={() => {
        setIsLightboxOpen(true);
      }}
      typeName={typeName}
      url={url}
    />
  );
});

const AudioVideoThumbnail = memo(function AudioVideoThumbnail({
  url,
  description,
  mimeType,
  typeName,
  icon,
}: Omit<FileThumbnailProps, "typeName"> & {
  typeName: "Audio" | "Video";
}) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  return (
    <>
      <FileThumbnailContents
        description={description}
        icon={icon}
        mimeType={mimeType}
        onClick={() => setIsLightboxOpen(true)}
        typeName={typeName}
        url={url}
      />
      <Lightbox onClose={() => setIsLightboxOpen(false)} open={isLightboxOpen}>
        {typeName === "Audio" ? (
          <audio controls>
            <source src={url} type={mimeType} />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <video
            controls
            style={{
              maxWidth: window.innerWidth * 0.75,
              maxHeight: window.innerHeight * 0.75,
            }}
          >
            <source src={url} type={mimeType} />
            Your browser does not support the video element.
          </video>
        )}
      </Lightbox>
    </>
  );
});

const FileThumbnail = memo(function FileThumbnail({
  url,
  description,
  mimeType,
  icon,
  typeName,
  restrictWidth,
}: FileThumbnailProps) {
  return (
    <a download={description} href={url}>
      <FileThumbnailContents
        description={description}
        icon={icon}
        mimeType={mimeType}
        restrictWidth={restrictWidth}
        typeName={typeName}
        url={url}
      />
    </a>
  );
});

const FileThumbnailContents = memo(function FileThumbnailContents({
  url,
  description,
  mimeType,
  icon,
  typeName,
  onClick,
  showTooltip,
  restrictWidth,
}: FileThumbnailProps & { onClick?: () => void }) {
  mimeType = mimeType || "";
  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.download = description || url;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className={attachmentThumbnailCss.fileContainer} onClick={onClick}>
      <div className={attachmentThumbnailCss.fileType}>{icon}</div>
      <div
        className={
          restrictWidth
            ? attachmentThumbnailCss.fileInfoWidthRestricted
            : attachmentThumbnailCss.fileInfo
        }
      >
        {description &&
          (showTooltip === false ? (
            <Tooltip title={description}>
              <p className={attachmentThumbnailCss.overflowEllipsis}>
                {description}
              </p>
            </Tooltip>
          ) : (
            <p className={attachmentThumbnailCss.overflowEllipsis}>
              {description}
            </p>
          ))}

        <p className={attachmentThumbnailCss.fileTypeName}>
          {typeName || mimeType}
        </p>
      </div>
      <RedoButton
        hierarchy={RedoButtonHierarchy.TERTIARY}
        IconLeading={DownloadIcon}
        onClick={(e) => {
          e.stopPropagation();
          handleDownload(url);
        }}
        size={RedoButtonSize.REGULAR}
        theme={RedoButtonTheme.NORMAL}
      />
    </div>
  );
});
